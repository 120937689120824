.import-popup {
    width: 50%;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
}

.import-popup__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.import-popup h2 {
    margin-bottom: 10px;
    font-size: 24px;
    text-align: center;
}

.import-popup p {
    margin-bottom: 20px;
    text-align: center;
}

.import-popup__textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    box-sizing: border-box;
    overflow:auto;
}

.import-popup__button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.import-popup__textarea-container {
    width: 80%;
}

.import-popup__button:active {
    background-color: #0056b3;
}

.import-popup__button:hover {
    background-color: #0056b3;
}

.import-popup__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}