.home-page {
    display: flex;
    flex-direction: column;
}

.main-content {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.plot-container {
    width: 65%;
}

.order-container {
    width: 35%;
}


.add-series-container {
    display: flex;
    flex-direction: row;
}

.series-selector {
    margin-top: 20px;
}