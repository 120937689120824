.layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.header-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

}

.header-button {
    padding: 8px 16px;
    font-size: 14px;
}