.import-confirmation-pop{
    min-height: 70vh;
    min-height: 800px;
}

.import-confirmation-table-container {
    overflow: auto;
    padding: 10px;
    min-height: 70vh;
}

.import-confirmation-table {
    width: 100%;
    border-collapse: collapse;
}

.import-confirmation-table th,
.import-confirmation-table td {
    padding: 8px;
    text-align: center;
}

.import-confirmation-table th {
    font-weight: bold;
}

.import-confirmation-table .separator {
    width: 2px;
    background-color: #ccc;
    border-left: 2px solid #ccc;
    border-right: 2px solid #ccc;
}

.confirm-button {
    margin-left: 10px;
}