.notification-table-container {
    overflow-x: auto;
    padding: 10px;
    min-height: 70vh;
}

.notification-table {
    width: 100%;
    border-collapse: collapse;
}

.notification-table th,
.notification-table td {
    padding: 8px;
    text-align: center;
}

.notification-table th {
    font-weight: bold;
}

.notification-table .separator {
    width: 2px;
    background-color: #ccc;
    border-left: 2px solid #ccc;
    border-right: 2px solid #ccc;
}


.ant-collapse-content {
    max-height: 150px;
    overflow: hidden;
}

.ant-collapse-item-active .ant-collapse-content {
    max-height: none;
}