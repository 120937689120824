.InvoicePopUp-container {
    border-radius: 10px;
    z-index: 9999;
}

.dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.close-button {
    margin-left: auto;
}

.popup-form-container {
    display: flex;
    flex-direction: column;
    height: 80vh;
}

.orders-table-container {
    table-layout: auto;
    width: 100%;
    overflow-x: auto;

}

.orders-table {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
}

.table-header-cell,
.table-cell {
    white-space: nowrap;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
    min-width: 60px;
}

.table-header-cell {
    font-weight: bold;
    background-color: #f5f5f5;
}

.table-row {
    border-bottom: 1px solid #eee;
}

.table-cell .ant-form-item {
    margin-bottom: 0;
}



.edit-button {
    margin-left: 10px;
}


.highlight-required {
    position: relative;
    border: 2px solid red !important;
}

.highlight-required::after {
    content: '*';
    color: red;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;

}

.input-field {
    max-width: 100%;
}