.form-container{
    padding-bottom: 20px;
}
.search-form {
    display: flex;
    align-items: start;
    gap: 10px;
    flex-wrap: nowrap;
    max-width: 100vw;
}

.search-field-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.search-field-group {

    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap:wrap;
    flex: 1;
    overflow: hidden;
}


.search-button-group {
    margin-top: 32px;
}

.search-form-item {
    margin-bottom: 0;
    min-width: 150px;
    max-width: 200px;
    width: 150px;
}

.search-select {
    border: none;
    box-shadow: none;
    text-align: center;
}


/* table contaniner part */

.main-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.generate-file-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 10px;
}

.table-content {
    display: flex;
    flex-direction: row;
    gap: 10px;

}


.table-control-buttons {
    display: flex;
    align-items: end;
    justify-content: end;;
}


.table-container {
    flex: 1;
    overflow: auto;

}


.table-header-column {
    display: flex;
    align-items: center;
    flex-direction: column;
}


.delete-icon-wrapper {
    position: relative;
    display: inline-block;
}

.delete-icon.filled {
    display: none;
}

.delete-icon-wrapper:hover .delete-icon {
    display: none;
}

.delete-icon-wrapper:hover .delete-icon.filled {
    display: inline;
}


.boomer-buttons{
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
    gap: 20px;
    /* justify-content: center; */
    
}


.ant-table-column-has-sorters {
    padding: 8px 0px !important;
}

.ant-table-cell {
    padding: 12px 5px !important;
}

.ant-select-selector  {
    padding: 7px !important;
}