.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.login-form {
    width: 400px;
    padding: 50px;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    font-size: 18px;
}

.site-form-item-icon {
    color: rgba(0, 0, 0, 0.35);
    font-size: 24px;
}

.login-form-button {
    width: 100%;
    height: 50px;
    font-size: 18px;
    background-color: #1890ff;
    border-color: #1890ff;
}

.login-form-button:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
}

p {
    color: red;
    font-size: 16px;
    text-align: center;
}



.login-input {
    height: 45px;
    font-size: 18px;
}



@media (max-width: 576px) {
    .login-form {
        width: 90%;
        padding: 30px;
    }
}